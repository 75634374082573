<template>
<div>
    <b-row>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-clipboard-list fa-md"></i> <span class="h5"> Evaluación de proveedor</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col md="12">
                            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                                <b-form @submit.stop.prevent="handleSubmit(enviarEvaluacion)">
                                    <b-col md="7">
                                        <b-form-group label="Proveedor:" class="mb-2">
                                            <span>{{datosEvaluacion.proveedor}}</span>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="2" class="mb-4">
                                        <validation-provider name="fecha" :rules="{ required: true }" v-slot="validationContext">
                                            <b-form-group label="Fecha:" class="mb-2">
                                                <b-form-input type="date" :state="getValidationState(validationContext)" v-model="datosEvaluacion.fecha"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                    <b-col md="12">
                                        <b-table bordered :items="datosEvaluacion.listaPreguntas" :fields="camposPreguntas" class="table-custom mt-2" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                                            <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                            <template v-slot:cell(respuesta)="row">
                                                <b-row>
                                                    <b-col md="6">
                                                        <star-rating style="display: inline-block;" class="text-center" v-if="row.item.tipoPregunta == 2" :star-size="20" :show-rating="false" v-model="row.item.valoracion"></star-rating>
                                                        <b-form-radio-group v-if="row.item.tipoPregunta == 1" small plain v-model="row.item.valoracion" :options="radioSN"></b-form-radio-group>
                                                    </b-col>
                                                    <b-col md="6" v-if="row.item.hasFichero == 1">
                                                        <b-form-file ref="file" v-model="row.item.archivoMeta" v-on:change="handleFileUpload" placeholder="Elija un archivo o arrástrelo aquí..." drop-placeholder="Arrastre el documento aquí.." browse-text="Subir"></b-form-file>
                                                    </b-col>
                                                </b-row>
                                            </template>
                                        </b-table>
                                    </b-col>
                                    <b-col md="12" class="mt-3">
                                        <span class="text-muted h6">Si desea realizar algún comentario adicional, por favor, hágalo a continuación:</span>
                                        <hr class="mb-3 mt-1">
                                        <b-form-group class="mb-2">
                                            <b-form-textarea v-model="datosEvaluacion.observacion" rows="4" max-rows="6"></b-form-textarea>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="12" v-if="!disabled && $route.params.token && $route.params.id " class="mt-4 mb-2">
                                        <b-button class="float-right" variant="dark" @click="registrarEvaluacion()"><li class="fas fa-share fa-sm mr-1"></li> Enviar evaluación</b-button>
                                    </b-col>
                                </b-form>
                            </validation-observer>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
    </b-row>
</div>
</template>

<script>
import StarRating from 'vue-star-rating';
import axios from "axios";
import CONSTANTES from "@/Constantes.js";
export default {
    components: {
        StarRating
    },
    data() {
        return {
            disabled: true,
            datosEvaluacion: {
                idEvaluacionProveedor: '',
                idCliente: '',
                fecha: '',
                proveedor: '',
                observacion: '',
                listaPreguntas: []
            },

            listaPreguntas: [{
                    idPregunta: 1,
                    pregunta: 'cuanto es uno mas uno',
                    tipoPregunta: 1,
                    hasFichero: 1,
                    archivoMeta: null
                },
                {
                    idPregunta: 2,
                    pregunta: 'cuanto es otro',
                    tipoPregunta: 2,
                    hasFichero: 2,
                    archivoMeta: null
                }
            ],

            camposPreguntas: [{
                key: "index",
                label: "N°",
                class: "text-center",
            }, {
                key: 'pregunta',
                label: 'Aparto analizado',
                class: 'text-center'
            }, {
                key: 'respuesta',
                label: 'Valoración',
                class: 'text-center'
            }],

            radioSN: [{
                value: 1,
                text: "SI",
            }, {
                value: 2,
                text: "NO",
            }]
        }
    },
    methods: {
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },

        handleFileUpload(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["file"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["file"].reset();
                    return;
                }

                // this.datosEquipo.certificadoCalibracion = this.$refs.file.files[0];
            }
        },
        registrarEvaluacion() {
            let me = this;
            me.disabled = true;

            const formData = new FormData();
            /*---Necesario para ruta de archivo --*/
            for (let i in me.datosEvaluacion.listaPreguntas) {
                formData.append("file-" + me.datosEvaluacion.listaPreguntas[i].idPreguntaEvaluacion, me.datosEvaluacion.listaPreguntas[i].archivoMeta);
            }

            formData.append("folder", 'evaluaciones');
            /*---Datos del objeto--*/
            formData.append("idCliente", me.datosEvaluacion.idCliente);
            formData.append("idEvaluacionProveedor", me.datosEvaluacion.idEvaluacionProveedor);
            formData.append("fechaCompletado", me.datosEvaluacion.fecha);
            formData.append("observacion", me.datosEvaluacion.observacion);

            formData.append("listaPreguntas", JSON.stringify(me.datosEvaluacion.listaPreguntas));

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/guardar-evaluacion-proveedor-contenido",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)

                    me.disabled = true;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;

                });
        },
        obtenerEvaluacion() {
            let me = this;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-evaluacion-proveedor-contenido", {
                        params: {
                            token: me.$route.params.token
                        },
                    }
                )
                .then(function (response) {
                    if (response.data[0].estado == 2) {
                        me.swat('info', 'Esta evaluación ya está registrada')
                        me.disabled = true;
                        me.datosEvaluacion.proveedor = response.data[0].razonSocial;
                        me.datosEvaluacion.fecha = response.data[0].fechaEnviado;
                        me.datosEvaluacion.observacion = response.data[0].observacion;
                        me.obtenerPreguntasEvaluacion();
                    } else {
                        me.datosEvaluacion.idCliente = response.data[0].idCliente;
                        me.datosEvaluacion.idEvaluacionProveedor = response.data[0].idEvaluacionProveedor;
                        me.datosEvaluacion.proveedor = response.data[0].razonSocial;
                        me.datosEvaluacion.fecha = response.data[0].fechaEnviado;
                        me.datosEvaluacion.observacion = response.data[0].observacion;
                        me.disabled = false;
                        me.obtenerPreguntasEvaluacion();
                    }

                })
                .catch(function (error) {
                    // me.swat('error', 'Algo salió mal!')
                    me.obtenerPreguntasEvaluacion();
                    me.disabled = true;
                });
        },

        obtenerPreguntasEvaluacion() {
            let me = this;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-preguntas-evaluacion-proveedor-contenido", {
                        params: {
                            idCliente: me.$route.params.id
                        },
                    }
                )
                .then(function (response) {

                    for (let i in response.data) {
                        me.datosEvaluacion.listaPreguntas.push({
                            idPreguntaEvaluacion: response.data[i].idPreguntaEvaluacion,
                            pregunta: response.data[i].pregunta,
                            hasFichero: response.data[i].hasFichero,
                            tipoPregunta: response.data[i].tipoPregunta,
                            valoracion: response.data[i].tipoPregunta == 2 ? 0 : 1,
                            archivoMeta: null,
                        })
                    }

                })
                .catch(function (error) {
                    // me.swat('error', 'Algo salió mal!')
                    me.disabled = true;
                });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    mounted() {
        if (this.$route.params.token && this.$route.params.id ) {
            this.obtenerEvaluacion();
        }
    }
}
</script>
